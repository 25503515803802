.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 10vh;
  background-color: $color-bg;

  &__content {
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-family: $font-family-secondary;
    line-height: $line-height;
    @include desktop {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  &__title {
    margin: 0;
    line-height: 0;
  }
}

.header-menu {
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list-item:not(:first-child) {
    margin-left: 1rem;
  }
}
