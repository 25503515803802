.demo {

  &__content {
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
  }

  &__flex-container {
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__flex-container_exception {
    display: flex;
    flex-direction: column-reverse;
    @include tablet {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__img-container {
    max-width: 800px;
  }

  &__text-container {
    width: 100%;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
    @include tablet {
      max-width: 500px;
      margin-left: 2.5rem;
    }
  }

  &__title {
    font-family: $font-family-secondary;
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;
    @include desktop {
      font-size: 35px;
      max-width: 300px;
    }
  }

  &__description {
    margin-top: 0.7rem;
    color: $color-grey;
    line-height: $line-height;
  }

  &__link {
    margin-top: 0.7rem;
    padding-bottom: 7px;
    font-size: 13px;
    border-bottom: 1px solid $color-cta;
    color: $color-cta;
    display: inline-block;
    text-decoration: none;
    &:hover {
      color: $color-light;
      border-bottom: 1px solid $color-light;
      transition: all 0.3s ease-out;
    }
  }

  &__icon {
    display: inline-block;
    height: 16px;
    min-width: 0;
    padding-left: 5px;
    transform: translateY(4px);
    &:hover {
      color: $color-light;
      transition: all 0.3s ease-out;
    }
  }
}
