.hero {
  &__content {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  &__img-container {
    max-width: 650px;
    margin: 0 auto;
  }

  &__text-container{
    width: 100%;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
  }

  &__title {
    font-family: $font-family-secondary;
    font-size: $font-size-titles;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;
    padding-left: 16px;
    padding-right: 16px;
    @include desktop {
      font-size: 80px;
    }
  }

  &__description {
    margin-top: 1rem;
    color: $color-grey;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 24px;
    line-height: $line-height;
    @include desktop {
      font-size: 32px;
    }
  }

  &__link {
    margin-top: 1.8rem;
    color: $color-light;
    text-decoration: none;
  }
}
