.testimonials {
  margin-top: 16px;

  &__content {
    width: 100%;
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
  }

  &__img-container--relative {
    position: relative;
    left: 7px;
    max-width: 25px;
    margin: 0;
    @include tablet {
      max-width: 50px;
      left: -10px;
      bottom: -10px;
      z-index: -1;
    }
  }

  &__flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include tablet {
      display: flex;

      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

}
