.features {
  margin-top: 8rem;
  margin-bottom: 8rem;

  &__content {
    width: 100%;
    max-width: 840px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  &__grid-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5rem;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 5rem;
      column-gap: 4rem;
    }
    @include desktop {
      column-gap: 8rem;
    }
  }

}

.feature {
  width: 100%;

  &__icon {
    width: 70px;
    height: auto;
  }

  &__title {
    margin-top: 1.5rem;
    font-family: $font-family-secondary;
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;
  }

  &__description {
    margin-top: 0.7rem;
    color: $color-grey;
    line-height: $line-height;
  }
}
