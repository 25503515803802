.footer-menu {

  @include tablet {
    margin-top: 0;
  }

  &:nth-of-type(2) {
    margin-left: 4rem;
    margin-top: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
  }

  &__list-item:not(:first-child) {
    margin-top: 1rem;
  }
}
